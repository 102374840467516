import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginLayoutComponent} from "./core/layouts/login-layout/login-layout.component";
import {DefaultLayoutComponent} from "./core/layouts/default-layout/default-layout.component";
import {AuthenticatedGuard} from "./pages/login/services/authenticated.guard";

const routes: Routes = [
  {
    path: 'auth',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
      },{
        path: 'register',
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'purchase',
        loadChildren: () => import('./pages/purchase-product-register/purchase-product-register.module').then(m => m.PurchaseProductRegisterModule)
      },{
        path: 'error',
        loadChildren: () => import('./core/error/error.module').then(m => m.ErrorModule)
      },{
        path: 'recover-password',
        loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
      },
      {
        path: 'invite-newUser',
        loadChildren: () => import('./pages/invitation-register/invitation-register.module').then(m => m.InvitationRegisterModule)
      }

    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'subscribed',
        loadChildren: () => import('./pages/licitations-followed/licitations-followed.module').then(m => m.LicitationsFollowedModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'owner-administration',
        loadChildren: () => import('./pages/owner-administration/owner-administration.module').then(m => m.OwnerAdministrationModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'categories',
        loadChildren: () => import('./pages/categories-management/categories-management.module').then(m => m.CategoriesManagementModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'categories-management/:id',
        loadChildren: () => import('./pages/users-config/admin-categories-management/admin-categories-management.module').then(m => m.AdminCategoriesManagementModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'licitation-detail/:id',
        loadChildren: () => import('./pages/licitation-detail/licitation-detail.module').then(m => m.LicitationDetailModule),
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'admin-configuration',
        loadChildren: () => import('./pages/admin-configuration/admin-configuration.module').then(m => m.AdminConfigurationModule),
        canActivate: [AuthenticatedGuard]
      },{
        path: 'users-config',
        loadChildren: () => import('./pages/users-config/users-config.module').then(m => m.UsersConfigModule)
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
